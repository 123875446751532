import { Box } from '@material-ui/core';
import React from 'react';
import styles from './QAIdol.module.scss';

export default function QAIdol({ data = [] }) {
  return (
    <Box display="flex" flexDirection="column" className={styles.container}>
      {data.map((item) => (
        <Box key={item.id}>
          <Box display="flex">
            <img
              alt="Q"
              width={24}
              height={24}
              src={require('src/images/Q-icon.svg')}
            />
            <p className={styles.question}>{item.question}</p>
          </Box>
          <p className={styles.answer}>{item.answer}</p>
        </Box>
      ))}
    </Box>
  );
}
